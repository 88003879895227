/* This is list of user scopes in CMP, Don't change the user scope order, add new once at last */
export const scope = [
    'selfService.write',
    'orderMgmt.read',
    'orderMgmt.write', // This scope defines the user as Admin Role
    'approvalMgmt.write',
    'landingzoneMgmt.read',
    'landingzoneMgmt.write',
    'cmp.auth',
    'CMPBetaUsers', // This scope defines the user as BetaUser Role
    'nsg.write',
    'nsg.read',
    'dashboard.read',
    'platform.admin',
];
export default scope;
