import { linuxVM, sqlPaaS, windowsVM, oracleIaaS, sqlIaaS, storageBlog, aksNS } from 'utils';
import {
    keyvaultCatalogDefinitionId,
    kafkaCatalogDefinitionId,
    AlbCatalogDefinitionId,
    eventhubDefinitionId,
    linuxSLESCatalogDefinitionId,
    SlesPgtCatalogDefinitionId,
    pECatalogDefinitionId,
    eipStackCatalogDefinitionId,
    adfCatalogDefinitionId,
    couchbaseDbDefinitionId,
    ApplicationGateWayURLCatalogDefinitionID,
    InternalApplicationGateWayURLCatalogDefinitionID,
    ExternalPublicLoadBalancerURLCatalogDefinitionID,
    linuxOELCatalogDefinitionId,
    windowsCatalogDefinitionId,
    sqlIaaSCatalogDefinitionId,
    linuxCatalogDefinitionId,
    oracleIaaSDefinitionId,
    oelOracleCatalogDefinitionId,
    NewInternalApplicationGateWayProvisioningCatalogDefinitionID,
    SlesPgtDBCatalogDefinitionId,
} from 'utils/catalogDefinitionId';
import { getKafkaOrderSummary } from './getKafkaSummary';
import { getNewInternalAppGatewaySummary } from './getNewInternalAppGatewaySummary';
import { getInternalAppGatewaySummary } from './getInternalAppGatewaySummary';
import { getExternalPublicLoadBalancerSummary } from './getExternalPublicLoadBalancerSummary';

export const getOrderSummary = ({ orders = [] }) => {
    const getServiceResourceTags = (components) => {
        const serviceResourceTags = {};
        const catalogShortName = {
            CFKAFKA: 'Kafka',
            AZSQLPAAS: 'SQL',
            AZAKSNS: 'AKS',
            AZSTORAGE: 'Storage Account',
        };
        components.map((x) => {
            serviceResourceTags[`${catalogShortName[x?.catalogService?.catalogConfig?.type]} Servicename`] =
                x?.catalogService?.catalogConfig?.tags?.Servicename;
            return serviceResourceTags;
        });
        return serviceResourceTags;
    };
    if (orders?.catalogServices && orders?.catalogServices.length) {
        const result = orders?.catalogServices?.map((item) => {
            const orderItemDetails = {
                orderId: orders?.id,
                createdByGpId: orders?.created?.by?.id,
                catalogType: item?.catalogType,
                catalogServiceId: item?.catalogServiceId,
                catalogServiceCode: item?.catalogServiceCode,
                quantity: item?.quantity,
                orderStatus: orders?.currentStatus?.status,
                osType: null,
                summary: {},
                eventHubDetails: [],
                dataDisks: [],
                environmentDetails: {},
                selectedNetworks: [],
                tags: {},
                applicationDetails: {},
                ...([
                    ...ApplicationGateWayURLCatalogDefinitionID,
                    ...InternalApplicationGateWayURLCatalogDefinitionID,
                    ...ExternalPublicLoadBalancerURLCatalogDefinitionID,
                ]?.includes(item.catalogServiceCode) && {
                    provisionDetails: {},
                }),
            };
            orderItemDetails.summary = {
                ...(kafkaCatalogDefinitionId.includes(item.catalogServiceCode) && getKafkaOrderSummary(item)),
                ...((linuxVM.includes(item.catalogServiceCode) ||
                    windowsVM.includes(item.catalogServiceCode) ||
                    oracleIaaS.includes(item.catalogServiceCode) ||
                    sqlIaaS.includes(item.catalogServiceCode)) && {
                    ...([
                        ...linuxOELCatalogDefinitionId,
                        ...linuxSLESCatalogDefinitionId,
                        ...SlesPgtCatalogDefinitionId,
                        ...SlesPgtDBCatalogDefinitionId,
                    ].includes(item.catalogServiceCode) && {
                        catalog: item?.catalogType,
                    }),
                    ...(![
                        ...linuxOELCatalogDefinitionId,
                        ...linuxSLESCatalogDefinitionId,
                        ...SlesPgtCatalogDefinitionId,
                        ...SlesPgtDBCatalogDefinitionId,
                    ].includes(item.catalogServiceCode) && {
                        catalog: `${item.catalogConfig.os.publisher}, ${item.catalogConfig.os.sku}`,
                    }),
                    ...(oracleIaaS.includes(item.catalogServiceCode) && {
                        catalog: item?.catalogType,
                    }),
                }),
                ...([...storageBlog, ...aksNS, ...eventhubDefinitionId, ...eipStackCatalogDefinitionId].includes(item?.catalogServiceCode) && {
                    catalog: item?.catalogType,
                }),
                ...(![...storageBlog, ...NewInternalApplicationGateWayProvisioningCatalogDefinitionID].includes(item.catalogServiceCode) && {
                    sector: item?.sector,
                }),
                ...(![
                    ...ApplicationGateWayURLCatalogDefinitionID,
                    ...InternalApplicationGateWayURLCatalogDefinitionID,
                    ...NewInternalApplicationGateWayProvisioningCatalogDefinitionID,
                ]?.includes(item.catalogServiceCode) && {
                    environment: item?.catalogConfig?.environmentDetails?.environment,
                }),
                ...(![
                    ...aksNS,
                    ...kafkaCatalogDefinitionId,
                    ...eventhubDefinitionId,
                    ...eipStackCatalogDefinitionId,
                    ...adfCatalogDefinitionId,
                    ...AlbCatalogDefinitionId,
                    ...ApplicationGateWayURLCatalogDefinitionID,
                    ...InternalApplicationGateWayURLCatalogDefinitionID,
                    ...ExternalPublicLoadBalancerURLCatalogDefinitionID,
                    ...NewInternalApplicationGateWayProvisioningCatalogDefinitionID,
                ].includes(item.catalogServiceCode) && {
                    'App Tier': item?.catalogConfig?.environmentDetails?.applicationTier,
                }),
                ...(![
                    ...ApplicationGateWayURLCatalogDefinitionID,
                    ...InternalApplicationGateWayURLCatalogDefinitionID,
                    ...NewInternalApplicationGateWayProvisioningCatalogDefinitionID,
                ]?.includes(item.catalogServiceCode) && {
                    region: item?.catalogConfig?.environmentDetails?.region,
                }),
                ...(sqlPaaS.includes(item?.catalogServiceCode) && {
                    'Server Collation': item?.catalogConfig?.azureSqlPurchase?.collation,
                    'Deployment Type': item?.catalogConfig?.deploymentType,
                    ...(item?.catalogConfig?.deploymentType === 'Elastic Pool' && {
                        'Elastic Pool Name': item?.catalogConfig?.elasticPool?.name || '-',
                    }),
                    'LogicalHost Name': item.catalogConfig.azureSqlPaasHost.name,
                    'SQL Database Name': item.catalogConfig.azureSqlPaasHost.dbName,
                    GeoReplication: item?.catalogConfig?.disasterRecovery === true || item?.catalogConfig?.geoReplication === true ? 'Yes' : 'No',
                    ...(item?.catalogConfig?.disasterRecovery === true &&
                        item?.catalogConfig?.failOverGroupName &&
                        item?.catalogConfig?.secondaryServerName && {
                            secondaryServerName: item?.catalogConfig?.secondaryServerName,
                            failOverGroupName: item?.catalogConfig?.failOverGroupName,
                        }),
                    'Deployment Model': item.catalogConfig.azureSqlPaasHost.purchaseModel,
                    ...(item.catalogConfig.azureSqlPaasHost.purchaseModel === 'DTU' && {
                        'Service Tier': item.catalogConfig.azureSqlPurchase.sku.name,
                        type: item.catalogConfig.azureSqlPurchase.sku.tier,
                        capacity: item.catalogConfig.azureSqlPurchase.sku.capacity,
                    }),
                    ...(item.catalogConfig.azureSqlPaasHost.purchaseModel === 'vCore' && {
                        'Service Tier': item.catalogConfig.azureSqlPurchase.sku.tier,
                        'vCore Count': item.catalogConfig.azureSqlPurchase.sku.capacity,
                    }),
                    retention: `${item.catalogConfig.azureSqlRetention.days} Days`,
                    'Backup Interval': `${item.catalogConfig.azureSqlRetention.diffBackupInterval} Days`,
                    'Storage Size': `${item.catalogConfig.azureSqlPurchase.maxSizeBytes / (1024 * 1024 * 1024)} GB`,
                    zoneRedundancy: item?.catalogConfig?.zoneRedundancy === true ? 'Yes' : 'No',
                }),
                ...((linuxVM.includes(item.catalogServiceCode) ||
                    windowsVM.includes(item.catalogServiceCode) ||
                    oracleIaaS.includes(item.catalogServiceCode) ||
                    sqlIaaS.includes(item.catalogServiceCode)) && {
                    ...([...linuxSLESCatalogDefinitionId, ...SlesPgtCatalogDefinitionId, ...SlesPgtDBCatalogDefinitionId].includes(item.catalogServiceCode) && {
                        OS: item?.catalogConfig?.os?.sku,
                    }),
                    'VM Type': item.catalogConfig.vmType,
                    'OS disk type': item.catalogConfig.os.osDiskType,
                    domain: item?.catalogConfig?.environmentDetails?.domain,
                }),
                ...(linuxVM.includes(item.catalogServiceCode) &&
                    item.catalogConfig.samAccountId && {
                        'SAM Account ID': item.catalogConfig.samAccountId,
                    }),
                ...(windowsVM.includes(item.catalogServiceCode) && {
                    'AD OU': item?.catalogConfig?.environmentDetails?.adOu,
                    'AD Group': item?.catalogConfig?.adGroup,
                }),
                ...(sqlIaaS.includes(item?.catalogServiceCode) && {
                    'AD OU': item?.catalogConfig?.environmentDetails?.adOu,
                }),
                ...((linuxVM.includes(item?.catalogServiceCode) ||
                    windowsVM.includes(item?.catalogServiceCode) ||
                    oracleIaaS.includes(item?.catalogServiceCode) ||
                    sqlIaaS.includes(item?.catalogServiceCode)) && {
                    'OS Timezone': item?.timeZone,
                }),
                ...(storageBlog.includes(item?.catalogServiceCode) && {
                    'Cost Center': item?.catalogConfig?.environmentDetails?.costCenter,
                }),
                ...([...SlesPgtCatalogDefinitionId, ...SlesPgtDBCatalogDefinitionId]?.includes(item?.catalogServiceCode) && {
                    'SAP SID Name': item?.catalogConfig?.sapUserName,
                    'SAP GID Name': item?.catalogConfig?.sapGroupName,
                    'SAP UID': item?.catalogConfig?.sapUserId,
                    'SAP GID': item?.catalogConfig?.sapGroupId,
                    Offer: item?.catalogConfig?.os?.offer,
                    sapSolution: item?.catalogConfig?.sapSolution,
                }),
                ...([...SlesPgtDBCatalogDefinitionId]?.includes(item?.catalogServiceCode) && {
                    dataBaseSize: item?.catalogConfig?.dataBaseSize,
                }),
                ...(AlbCatalogDefinitionId.includes(item?.catalogServiceCode) ||
                    (pECatalogDefinitionId.includes(item?.catalogServiceCode) &&
                        ![
                            ...ApplicationGateWayURLCatalogDefinitionID,
                            ...InternalApplicationGateWayURLCatalogDefinitionID,
                            ...ExternalPublicLoadBalancerURLCatalogDefinitionID,
                            ...NewInternalApplicationGateWayProvisioningCatalogDefinitionID,
                        ]?.includes(item?.catalogServiceCode) && {
                            'Cost Center': item?.catalogConfig?.environmentDetails?.costCenter,
                            'Project Name': item?.catalogConfig?.environmentDetails?.projectName,
                        })),
                ...(!storageBlog.includes(item?.catalogServiceCode) &&
                    !keyvaultCatalogDefinitionId.includes(item?.catalogServiceCode) &&
                    !AlbCatalogDefinitionId.includes(item?.catalogServiceCode) &&
                    !kafkaCatalogDefinitionId.includes(item?.catalogServiceCode) &&
                    !eventhubDefinitionId.includes(item?.catalogServiceCode) &&
                    !pECatalogDefinitionId.includes(item?.catalogServiceCode) &&
                    !adfCatalogDefinitionId.includes(item?.catalogServiceCode) &&
                    !eipStackCatalogDefinitionId.includes(item?.catalogServiceCode) &&
                    !ApplicationGateWayURLCatalogDefinitionID?.includes(item.catalogServiceCode) &&
                    !InternalApplicationGateWayURLCatalogDefinitionID?.includes(item.catalogServiceCode) &&
                    !ExternalPublicLoadBalancerURLCatalogDefinitionID?.includes(item.catalogServiceCode) &&
                    !NewInternalApplicationGateWayProvisioningCatalogDefinitionID?.includes(item.catalogServiceCode) && {
                        'Cost Center': item?.catalogConfig?.environmentDetails?.costCenter,
                        'Project Name': item?.catalogConfig?.environmentDetails?.projectName,
                        'Billing Entity': item?.catalogConfig?.billingEntity,
                    }),
                ...(keyvaultCatalogDefinitionId.includes(item?.catalogServiceCode) &&
                    eventhubDefinitionId.includes(item?.catalogServiceCode) && {
                        'Cost Center': item?.catalogConfig?.environmentDetails?.costCenter,
                        'Project Name': item?.catalogConfig?.environmentDetails?.projectName,
                    }),
                // 'Cost Center': item.catalogConfig.environmentDetails.costCenter,
                ...(storageBlog.includes(item?.catalogServiceCode) && {
                    'Access Tier': item?.catalogConfig?.accessTier,
                    'Storage Account Name': item?.catalogConfig?.accountName,
                    'Container Name': item?.catalogConfig?.containerName,
                    Capacity: item?.catalogConfig?.capacity,
                    Redundancy: item?.catalogConfig?.redundancy,
                    Performance: item?.catalogConfig?.performance,
                    'ADLS Gen2 Enabled': item?.catalogConfig?.isAdls ? 'Yes' : 'No',
                }),
                ...(aksNS.includes(item.catalogServiceCode) && {
                    Department: item?.catalogConfig?.environmentDetails?.department,
                    Cluster: item.catalogConfig?.cluster,
                    'Catalog Size': item?.catalogConfig?.size,
                    'Business Unit': item?.catalogConfig?.environmentDetails?.businessUnit,
                    // isaDocument: item?.catalogConfig?.isaDocument,
                    Project: item?.catalogConfig?.project,
                    'AD Group': item?.catalogConfig?.adGroup,
                    'Application Support DL': item?.catalogConfig?.appSupportMailId,
                }),
                ...(AlbCatalogDefinitionId.includes(item.catalogServiceCode) && {
                    instanceName: item?.catalogConfig?.instanceName,
                    FQDNS: item?.catalogConfig?.fqdns,
                    frontendPoolName: item?.catalogConfig?.frontEndPoolName,
                    backendPoolName: item?.catalogConfig?.backEndPools[0]?.poolName,
                    poolConfigurations: item?.catalogConfig?.backEndPools[0]?.poolConfig,
                    virtualMachines: item?.catalogConfig?.backEndPools[0]?.virtualMachines
                        ?.map((vm) => `${vm?.virtualMachineName} (IP: ${vm?.ipAddress})`)
                        ?.join(', '),
                    vNet: item?.catalogConfig?.environmentDetails?.virtualNetwork,
                    rulesName: item?.catalogConfig?.loadBalancerRules[0]?.rulesName,
                    protocol: item?.catalogConfig?.loadBalancerRules[0]?.protocol,
                    frontendPort: item?.catalogConfig?.loadBalancerRules[0]?.frontEndPort,
                    backendPort: item?.catalogConfig?.loadBalancerRules[0]?.backEndPort,
                    sessionPersistance: item?.catalogConfig?.loadBalancerRules[0]?.sessionPersistance,
                    ...(item?.catalogConfig?.loadBalancerRules[0]?.protocols?.toLowerCase() === 'tcp' && {
                        timeOut: item?.catalogConfig?.loadBalancerRules[0]?.idealTimeout,
                    }),
                    healthProbeName: item?.catalogConfig?.loadBalancerRules[0]?.healthProbe?.healthProbeName,
                    healthProbeProtocol: item?.catalogConfig?.loadBalancerRules[0]?.healthProbe?.protocols,
                    healthProbePort: item?.catalogConfig?.loadBalancerRules[0]?.healthProbe?.port,
                    ...(['http', 'https'].includes(item?.catalogConfig?.loadBalancerRules[0]?.healthProbe?.protocols?.toLowerCase()) && {
                        healthProbePathUrl: item?.catalogConfig?.loadBalancerRules[0]?.healthProbe?.pathUrl,
                    }),
                }),
                ...(eventhubDefinitionId.includes(item.catalogServiceCode) && {
                    'Namespace Exist': item?.catalogConfig?.namespaceExist,
                    'Namespace Name': item?.catalogConfig?.namespaceName,
                    Performance: item?.catalogConfig?.performance,
                    ...(item?.catalogConfig?.performance === 'Standard' && {
                        'Throughput Unit': item?.catalogConfig?.throughputUnit,
                        'Enable AutoInflate': item?.catalogConfig?.enableAutoInflate,
                    }),
                    ...(item?.catalogConfig?.performance === 'Standard' &&
                        item?.catalogConfig?.enableAutoInflate === 'yes' && {
                            'AutoInflate Maximum ThroughputUnit': item?.catalogConfig?.autoInflateMaximumThroughputUnit,
                        }),
                    ...(item?.catalogConfig?.performance === 'Premium' && {
                        'Processing Unit': item?.catalogConfig?.processingUnit,
                    }),
                    ...(item?.catalogConfig?.environmentDetails?.environment === 'Production' && {
                        'Enable GeoReplication': item?.catalogConfig?.enableGeoReplication,
                    }),
                    ...(item?.catalogConfig?.environmentDetails?.environment === 'Production' &&
                        item?.catalogConfig?.enableGeoReplication === 'yes' && {
                            'Geo-replication Region': item?.catalogConfig?.geoRecoveryRegion,
                        }),
                    'TLS Version': item?.catalogConfig?.tlsVersion,
                    Project: item?.catalogConfig?.environmentDetails?.projectName,
                }),

                ...(!sqlPaaS.includes(item.catalogServiceCode) &&
                    !storageBlog.includes(item.catalogServiceCode) &&
                    !aksNS.includes(item.catalogServiceCode) &&
                    !AlbCatalogDefinitionId.includes(item.catalogServiceCode) &&
                    !keyvaultCatalogDefinitionId.includes(item?.catalogServiceCode) &&
                    !kafkaCatalogDefinitionId.includes(item?.catalogServiceCode) &&
                    !eventhubDefinitionId.includes(item?.catalogServiceCode) &&
                    !pECatalogDefinitionId.includes(item?.catalogServiceCode) &&
                    !adfCatalogDefinitionId.includes(item?.catalogServiceCode) &&
                    !eipStackCatalogDefinitionId.includes(item?.catalogServiceCode) &&
                    !couchbaseDbDefinitionId.includes(item?.catalogServiceCode) &&
                    !ApplicationGateWayURLCatalogDefinitionID?.includes(item?.catalogServiceCode) &&
                    !InternalApplicationGateWayURLCatalogDefinitionID?.includes(item?.catalogServiceCode) &&
                    !NewInternalApplicationGateWayProvisioningCatalogDefinitionID?.includes(item?.catalogServiceCode) &&
                    !ExternalPublicLoadBalancerURLCatalogDefinitionID && {
                        'Server Refresh Enabled': `${item?.catalogConfig?.serverRefresh === true ? 'Yes' : 'No'}`,
                        ...(item?.catalogConfig?.serverRefresh === true && {
                            'Retiring ServerName': item?.catalogConfig?.retiringServerName,
                            'Scheduled Retirement Date': item?.catalogConfig?.scheduledRetirement,
                            'Disposal Reason': item?.catalogConfig?.disposalReason,
                        }),
                    }),
                ...([...windowsCatalogDefinitionId, ...linuxCatalogDefinitionId, ...sqlIaaSCatalogDefinitionId, ...oracleIaaSDefinitionId].includes(
                    item.catalogServiceCode
                ) && {
                    'Accelerated Networking Enabled': `${item?.catalogConfig?.acceleratedNetworking === true ? 'Yes' : 'No'}`,
                }),
                ...(oracleIaaS.includes(item.catalogServiceCode) && {
                    ...(item?.catalogConfig?.oracleId && {
                        oracleId: item?.catalogConfig?.oracleId,
                    }),
                    'Oracle Version': item?.catalogConfig?.oracleVersion,
                    'Database National Character Set': item?.catalogConfig?.dbNationalCharSet,
                    'Database Character Set': item?.catalogConfig?.dbCharacterSet,
                    'Database Name': item?.catalogConfig?.dbName,
                    'DB Size': `${item?.catalogConfig?.dbSize} GB`,
                }),
                ...(oelOracleCatalogDefinitionId?.includes(item?.catalogServiceCode) && {
                    'NVMe Support': item?.catalogConfig?.nvmeSupport === true ? 'Yes' : 'No',
                    ...(item?.catalogConfig?.nvmeSupport === true && {
                        catalogSize: item?.catalogConfig?.catalogSize,
                    }),
                    'OS SKU Image Name': item?.catalogConfig?.os?.osSkuImageName,
                }),
                ...(sqlIaaS.includes(item.catalogServiceCode) && {
                    'Sql Version': item?.catalogConfig?.sqlVersion,
                    'Database Name': item?.catalogConfig?.dbName,
                    'DB Size': `${item?.catalogConfig?.dbSize} GB`,
                    'Server Collation': item.catalogConfig.sqlServerCollation,
                    'Services installed': item.catalogConfig.services?.length > 1 ? item.catalogConfig.services.join(', ') : item.catalogConfig.services,
                }),
                ...(keyvaultCatalogDefinitionId.includes(item.catalogServiceCode) && {
                    'Key Vault Name': item?.catalogConfig?.keyVaultName,
                    'Pricing Tier': item?.catalogConfig?.pricingTier,
                    'Vault Access Policy': item?.catalogConfig?.vaultAccessPolicy,
                }),
                ...(pECatalogDefinitionId.includes(item.catalogServiceCode) && {
                    Subscription: item?.catalogConfig?.subscription,
                    'Resource Type': item?.catalogConfig?.resourceType,
                    Resource: item?.catalogConfig?.resource,
                    Target_Sub_Resource: item?.catalogConfig?.targetSubResource,
                    'Private EndPointType': item?.catalogConfig?.privateEndpointType,
                    ...(item?.catalogConfig?.privateEndpointType === 'Isolated' && {
                        'Databricks Subscription': item?.catalogConfig?.databricksSubscription,
                        'Databrick/AML Workspace': item?.catalogConfig?.databricks,
                    }),
                }),
                ...(eipStackCatalogDefinitionId.includes(item.catalogServiceCode) && {
                    'Domain Name Service': `${item?.catalogConfig?.isDnsRequired === true ? 'Yes' : 'No'}`,
                    ...(item?.catalogConfig?.isDnsRequired && {
                        'FQDN Name': item?.catalogConfig?.fqdn,
                        'IP Address': item?.catalogConfig?.ipAddress,
                        'SSL Certificate': item?.catalogConfig?.sslCertificate,
                    }),
                    'ECE Integration': `${item?.catalogConfig?.isEceRequired === true ? 'Yes' : 'No'}`,
                    ...(item?.catalogConfig?.isEceRequired && {
                        'IT LDAP Group': item?.catalogConfig?.ldapGroup,
                    }),
                    'CLE Integration': `${item?.catalogConfig?.isEceRequired === true ? 'Yes' : 'No'}`,
                    ...(item?.catalogConfig?.isCleRequired && {
                        'Catalog Size ': item?.catalogConfig?.ldapGroup,
                        'ServiceNow Assignment Group': item?.catalogConfig?.serviceNowAssignmentGroup,
                    }),
                    'Conjure Integration': `${item?.catalogConfig?.isConjourRequired === true ? 'Yes' : 'No'}`,
                    ...(item?.catalogConfig?.isConjourRequired && {
                        'Safe Name': item?.catalogConfig?.safeName,
                        SOX: item?.catalogConfig?.sox,
                    }),
                }),

                ...(adfCatalogDefinitionId.includes(item.catalogServiceCode) && {
                    'Cost Center': item?.catalogConfig?.environmentDetails?.costCenter,
                    'Project Name': item?.catalogConfig?.environmentDetails?.projectName,
                    'Billing Entity': item?.catalogConfig?.billingEntity,
                    subscription: item?.catalogConfig?.environmentDetails?.subscription,
                    resourceGroup: item?.catalogConfig?.environmentDetails?.resourceGroup,
                    Data_Factory_Name: item?.catalogConfig?.resource?.name,
                    Version: item?.catalogConfig?.resource?.version,
                }),
                ...(ApplicationGateWayURLCatalogDefinitionID?.includes(item.catalogServiceCode) && {
                    'Application Name': item?.catalogConfig?.applicationName,
                    'Application Owner ID': item?.catalogConfig?.applicationOwnerId,
                    Environment: item?.catalogConfig?.environment,
                    sector: item?.catalogConfig.sector,
                    'Application Service': item.catalogConfig?.applicationService,
                    // Region: item?.catalogConfig?.region,
                    'Budget Code': item?.catalogConfig?.budgetCode,
                    'Application URL': item?.catalogConfig?.applicationUrl,
                    'SSL Encryption': item?.catalogConfig?.sslEncryption === true ? 'Yes' : 'No',
                    ...(item?.catalogConfig?.sslEncryption === true && {
                        SupportBackendCertificate: item?.catalogConfig?.supportBackendCertificate,
                    }),
                    ...(item?.catalogConfig?.routeType && {
                        RouteType: item?.catalogConfig?.routeType,
                    }),
                    ...(item?.catalogConfig?.routingPath && {
                        RoutingPath: item?.catalogConfig?.routingPath,
                    }),
                    Program: item?.catalogConfig?.program || '-',
                    BackEndHealthProbePath: item?.catalogConfig?.backendHealthProbePath,
                    'Backend FQDN': item?.catalogConfig?.backendFqdn,
                    ...(item?.catalogConfig?.isaNumber && {
                        'ISA Number': item?.catalogConfig?.isaNumber,
                    }),
                    ...(item?.catalogConfig?.scanNumber && {
                        'SCAN Number': item?.catalogConfig?.scanNumber,
                    }),
                    ...(item?.catalogConfig?.adGroup && {
                        'AD Group': item?.catalogConfig?.adGroup,
                    }),
                    ...(item?.catalogConfig?.appSupportMailId && {
                        'Application Support Mail ID': item?.catalogConfig?.appSupportMailId,
                    }),
                    ...(item?.catalogConfig?.filesUploadResponse && {
                        uploadScan: item?.catalogConfig?.filesUploadResponse?.uploadResponses,
                    }),
                    ...(item?.catalogConfig?.certificateOwnerOne && {
                        'Certificate Owner 1 ID': item?.catalogConfig?.certificateOwnerOne,
                    }),
                    ...(item?.catalogConfig?.certificateOwnerTwo && {
                        'Certificate Owner 2 ID': item?.catalogConfig?.certificateOwnerTwo,
                    }),
                    'GTM LoadBalancer IP Address': item?.catalogConfig?.gtmLoadBalancerIpAddress,
                }),
                ...(InternalApplicationGateWayURLCatalogDefinitionID?.includes(item.catalogServiceCode) && getInternalAppGatewaySummary(item)),
                ...(ExternalPublicLoadBalancerURLCatalogDefinitionID?.includes(item.catalogServiceCode) && getExternalPublicLoadBalancerSummary(item)),
                ...(NewInternalApplicationGateWayProvisioningCatalogDefinitionID?.includes(item.catalogServiceCode) && getNewInternalAppGatewaySummary(item)),
                ...(item?.estimatedCost &&
                    ![
                        ...ApplicationGateWayURLCatalogDefinitionID,
                        ...InternalApplicationGateWayURLCatalogDefinitionID,
                        ...ExternalPublicLoadBalancerURLCatalogDefinitionID,
                        ...NewInternalApplicationGateWayProvisioningCatalogDefinitionID,
                    ]?.includes(item.catalogServiceCode) && {
                        cost: item?.estimatedCost,
                    }),
            };
            if (item?.catalogConfig?.os) {
                orderItemDetails.osType = item?.catalogConfig?.os?.osType;
                if (item?.catalogConfig?.diskDefinitions?.length > 0) {
                    orderItemDetails.dataDisks = [...orderItemDetails.dataDisks, ...item?.catalogConfig?.diskDefinitions];
                }
            }
            if (item?.catalogConfig?.eventHubDetails && item?.catalogConfig?.eventHubDetails?.length > 0) {
                orderItemDetails.eventHubDetails = [...orderItemDetails.eventHubDetails, ...item?.catalogConfig?.eventHubDetails];
            }
            if (
                item?.catalogConfig?.environmentDetails &&
                item?.catalogConfig?.environmentDetails?.selectedNetworks &&
                item?.catalogConfig?.environmentDetails?.selectedNetworks?.length > 0
            ) {
                orderItemDetails.selectedNetworks = [...orderItemDetails.selectedNetworks, ...item?.catalogConfig?.environmentDetails?.selectedNetworks];
            }
            if (item?.catalogConfig?.environmentDetails) {
                Object.keys(item?.catalogConfig?.environmentDetails)?.forEach((label) => {
                    const excludeEnvFields = [
                        'projectName',
                        'adOu',
                        'domain',
                        'costCenter',
                        'region',
                        'applicationTier',
                        'environment',
                        'landingZoneId',
                        'selectedNetworks',
                        'appTierCode',
                        'costCenter',
                        'frontendPoolName',
                        'backendPoolName',
                        'subscriptionId',
                    ];
                    if (!excludeEnvFields.includes(label)) {
                        orderItemDetails.environmentDetails = {
                            ...orderItemDetails?.environmentDetails,
                            [label === 'drPriority' ? 'DR Priority' : label]:
                                label === 'availabilityZones' && item?.catalogConfig?.environmentDetails[label]?.length > 0
                                    ? item?.catalogConfig?.environmentDetails[label]?.join(', ')
                                    : item?.catalogConfig?.environmentDetails[label] || null,
                            ...(couchbaseDbDefinitionId.includes(item.catalogServiceCode) &&
                                label === 'asr' && {
                                    asr: item?.catalogConfig?.environmentDetails[label] === false ? 'disabled' : 'enabled',
                                }),
                        };
                    }
                });
            }
            if (
                item?.catalogConfig?.environmentDetails &&
                [
                    ...ApplicationGateWayURLCatalogDefinitionID,
                    ...InternalApplicationGateWayURLCatalogDefinitionID,
                    ...ExternalPublicLoadBalancerURLCatalogDefinitionID,
                ].includes(item.catalogServiceCode)
            ) {
                orderItemDetails.environmentDetails = {
                    ...orderItemDetails?.environmentDetails,
                    ...([...ApplicationGateWayURLCatalogDefinitionID].includes(item.catalogServiceCode) && {
                        'Subscription ID': item?.catalogConfig?.environmentDetails?.subscriptionId,
                    }),
                };
            }

            orderItemDetails.environmentDetails = {
                ...orderItemDetails?.environmentDetails,
                ...((linuxVM.includes(item?.catalogServiceCode) ||
                    windowsVM.includes(item?.catalogServiceCode) ||
                    oracleIaaS.includes(item?.catalogServiceCode) ||
                    sqlIaaS.includes(item?.catalogServiceCode)) && {
                    recoveryVaultName: item?.recoveryVaultName,
                    recoveryVaultPolicyName: item?.recoveryVaultPolicyName,
                    recoveryVaultResourceGroup: item?.recoveryVaultResourceGroup,
                }),
            };

            orderItemDetails.tags = { ...orderItemDetails?.tags, ...item?.catalogConfig?.tags };

            if (keyvaultCatalogDefinitionId.includes(item?.catalogServiceCode)) {
                delete orderItemDetails.tags.Billingentity;
            }
            if (eipStackCatalogDefinitionId.includes(item?.catalogServiceCode)) {
                orderItemDetails.connections = item?.catalogConfig?.connections;
                orderItemDetails.components = item?.catalogConfig?.components?.map(
                    (component) =>
                        getOrderSummary({
                            orders: { ...component, catalogServices: [{ ...component?.catalogService }] },
                        })?.[0]
                );
                orderItemDetails.environmentDetails = {
                    ...orderItemDetails?.environmentDetails,
                    projectName: item?.catalogConfig?.environmentDetails.projectName,
                };
                orderItemDetails.tags = {
                    ...orderItemDetails?.tags,
                    ...getServiceResourceTags(item?.catalogConfig?.components),
                };
                delete orderItemDetails?.environmentDetails?.applicationManagerEmail;
                delete orderItemDetails?.environmentDetails?.businessService;
            }
            if (couchbaseDbDefinitionId.includes(item?.catalogServiceCode)) {
                orderItemDetails.osType = 'Linux';
                orderItemDetails.instance = {
                    Cluster: item?.catalogConfig?.cluster,
                    'Couchbase DB Version': item?.catalogConfig?.dbVersion,
                    'No. of Servers': item?.catalogConfig?.nodesCount,
                };
                orderItemDetails.components = item?.catalogConfig?.components?.map((component) => {
                    const catalogConfig = component?.catalogService?.catalogConfig;
                    return {
                        'Os Type': `${catalogConfig?.os?.publisher},${catalogConfig?.os?.sku}`,
                        'Vm Type': catalogConfig?.vmType,
                        Domain: catalogConfig?.environmentDetails?.domain,
                        'HA Deployment': catalogConfig?.haDeployment,
                        ...(catalogConfig?.haDeployment === 'Availability Zones' && {
                            'Availability Zones': catalogConfig?.availabilityZones?.toString(),
                        }),
                        'SAM Account': catalogConfig?.samAccountId,
                        'Time Zone': component?.catalogService?.timeZone,
                        'SSL Certificate': catalogConfig?.sslCertificate,
                    };
                });
                orderItemDetails.dataDisks = item?.catalogConfig?.components?.map((component) => ({
                    dataDisk: component?.catalogService?.catalogConfig?.diskDefinitions,
                }));
                orderItemDetails.serviceMappings = item?.catalogConfig?.serviceMappings?.map((mapping) => ({
                    clusterService: mapping?.clusterService,
                    memoryQuota: mapping?.memoryQuota,
                    nodes: mapping?.nodes?.toString(),
                    storagePath: mapping?.storagePath,
                }));
                orderItemDetails.buckets = item?.catalogConfig?.buckets?.map((bucket) => ({
                    BucketType: bucket?.bucketType,
                    BucketMemSize: bucket?.bucketMemSize,
                    BucketNames: bucket?.bucketNames?.toString(),
                }));
            }
            Object.keys(orders?.entityDetails).forEach((label) => {
                if (!['applicationManagerGpid'].includes(label)) {
                    orderItemDetails.applicationDetails = {
                        ...orderItemDetails?.applicationDetails,
                        [label]: orders?.entityDetails[label] || null,
                    };
                }
            });
            if (
                [
                    ...ApplicationGateWayURLCatalogDefinitionID,
                    ...InternalApplicationGateWayURLCatalogDefinitionID,
                    ...ExternalPublicLoadBalancerURLCatalogDefinitionID,
                ].includes(item?.catalogServiceCode) &&
                item?.provisionedDetails &&
                Object.keys(item?.provisionedDetails)?.length > 0
            ) {
                Object.keys(item?.provisionedDetails).forEach((label) => {
                    if (!['applicationManagerGpid'].includes(label)) {
                        orderItemDetails.provisionDetails = {
                            ...orderItemDetails?.provisionDetails,
                            [label]: item?.provisionedDetails[label] || null,
                        };
                    }
                });
            }

            return orderItemDetails;
        });
        return result || [];
    }
    return [];
};
export default getOrderSummary;
