import { RICatalogCode001A, RICatalogCode002, RICatalogCode003, RICatalogCode003A, RICatalogCode004 } from 'pages/reference-implementation/utils/RICatalogCode';
import { MdtCatalogDefinitionId, SecurityToolCatalogDefinitionId } from './catalogDefinitionId';

export const scopedCatalogs = [
    RICatalogCode001A,
    RICatalogCode002,
    RICatalogCode003,
    RICatalogCode003A,
    RICatalogCode004,
    ...MdtCatalogDefinitionId,
    ...SecurityToolCatalogDefinitionId,
];

export default scopedCatalogs;
